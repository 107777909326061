<template>
	<v-container
		fill-height
		fluid
		grid-list-xl
	>
		<v-layout
		>
			<v-skeleton-loader
				:loading="loading"
				transition="fade-transition"
				style="width: 100%" 
				type="table"
			>
				<v-data-table
					:headers="headers"
					:items="sell_surveys"
					sort-by="first_name"
					class="elevation-1 col-12"
				>
					<template v-slot:top>
						<v-toolbar color="primary" style=" border-radius : 7px" class="white--text mb-3" >
							<v-toolbar-title>
								Sell Surveys
								<br/>
								<span
									class="subheading font-weight-bold caption"
								>
									List of all Sell Surveys
								</span>
							</v-toolbar-title>
							<v-divider
								class="mx-4"
								inset
								vertical
							></v-divider>
							<v-spacer></v-spacer>
            </v-toolbar>
					</template>
					<template v-slot:item.action="{ item }">
						<!-- <v-icon
							color="success"
							small
							class="mr-3"
							@click="editItem(item)"
						>
							edit
						</v-icon> -->
						<v-icon
							color="error"
							small
							@click="deleteItem(item)"
						>
							delete
						</v-icon>
					</template>
					<!-- <template v-slot:no-data>
						<v-btn color="primary" @click="initialize">Reset</v-btn>
					</template> -->
				</v-data-table>
			</v-skeleton-loader>
			<v-dialog
				v-model="deletedItem.show"
				width="500"
			>
				<v-card style="border-raduis: 32px">
					<v-card-title
						class="white--text red"
						primary-title
					>
						<v-row
							justify="space-between"
							align="start"
						>
							<p>Delete sell survey</p>
							<v-btn icon color="white" @click="deletedItem.show = false">
								<v-icon >close</v-icon>
							</v-btn>
						</v-row>
					</v-card-title>

					<v-card-text class=" subtitle-1 pt-5">
						Deleted sell surveys can not be retrived. 
						Are you sure you want to delete the sell survey? 
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="red"
							text
							@click="dialog = false"
						>
							Confirm
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-layout>
	</v-container>
</template>
<script>
  export default {
    data: () => ({
		loading: false,
			add_product_dialog: false,
			add_product_role_dialog: false,
			product_types: ['maize', 'eggs', 'drugs', 'white eggs', 'pipe corn'],
			sell_surveys: [
				{
					id: '121',
					name: 'Eggs',
					phone: '+233454444434',
					product: 'eggs',
					occupation: "Poultry Farmer",
					location: 'Ashanti Region',
					date_added: "some date"
				},
			],
		headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
				{ text: 'Name', align: 'left', value: 'name' },
				{ text: 'Phone', align: 'left', value: 'phone' },
				{ text: 'Product', value: 'product', align : 'left' },
				{ text: 'Occupation', value: 'occupation', align : 'left' },
				{ text: 'Location', value: 'location', align : 'left' },
				{ text: 'Date Added', value: 'date_added', align : 'left' },
				{ text: 'Actions', value: 'action', align: 'center', sortable: false },
		],
			// account_types: ['Admin', 'Agency'],
			// companies: ['AgroInova Ghana', 'Alex Farms'],
			editedIndex: -1,
			deletedItem: {
				show: false
			},
      editedItem: {
      },
      defaultItem: {
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New sell survey' : 'Edit sell survey'
      },
    },

    watch: {
      add_product_dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
				this.$http.get('https://jsonplaceholder.typicode.com/sell surveys')
					.then(res=>{
						this.sell_surveys = res.data.map(sell_survey =>{
							let name = sell_survey.name.split(' ')
							return {
								...sell_survey,
								first_name : name[0],
								last_name : name[1],
								company : sell_survey.company.name,
								account_type: 'Agent',
								date_added: 'Wed Jan 22 2020 15:34:14'
							}
						})
					}).catch(err=>{
						console.error(err)
					})
      },

      editItem (item) {
        this.editedIndex = this.sell_surveys.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.add_product_dialog = true
      },

      deleteItem (item) {
        const index = this.sell_surveys.indexOf(item)
				this.deletedItem = { show: true, ...item }
				console.log(this.deletedItem)
				// && this.sell_surveys.splice(index, 1)
      },

      close () {
        this.add_product_dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.sell_surveys[this.editedIndex], this.editedItem)
        } else {
          this.sell_surveys.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>